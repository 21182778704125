<template>
   
  <v-data-table
    :headers="datos[0].detalles? dessertHeaders: dessertDetalles "
    :items="datos"
    :single-expand="false" 
    item-key="codigo"
    :show-expand="datos[0].detalles? true: false"
    class="elevation-1"
    hide-default-footer
    dense
  >
    <template v-slot:expanded-item="{ headers, item }" >
      <td :colspan="headers.length" v-if="item.detalles &&  item.detalles.length > 0">
       
        <tabletExpander :dessertHeaders="dessertHeaders" :datos="item.detalles" :dessertDetalles="dessertDetalles" > </tabletExpander>
      </td>
    </template>
  </v-data-table>
 
</template>
  <script>
import tabletExpander from '../componentes/tabletExpander.vue'
export default {
  components: { tabletExpander: () => import('./tabletExpander.vue') },
  props:{
    dessertHeaders: Array,
    datos: Array,
    dessertDetalles: Array,
  },
  data() {
    return {}
  },
}
</script>