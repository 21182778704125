<template>
  <div>
    <p class="mb-6">Compras agrupadas por cuenta contable</p>

    <v-card>
      <v-card-subtitle>Filtros</v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <SelectGrupoNegocio
              v-model="filtros.id_grupo_negocio"
              :add="false"
              :dense="true"
              :outlined="true"
              :clearable="true"
              :ind_principal="true"
              :storePuesto="true"
            />
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <select-puesto
              v-model="filtros.id_puesto"
              :add="false"
              :dense="true"
              :outlined="true"
              :clearable="true"
              :ind_principal="true"
              :storePuesto="false"
              :filtros="{ id_grupo_negocio: filtros.id_grupo_negocio }"
            ></select-puesto>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <dos-fechas :fechas="[filtros.desde, filtros.hasta]" @getFechas="getFechas" :icon="true"></dos-fechas>
          </v-col>
          <v-col cols="12" class="text-right">
            <v-btn small :loading="cargando" :disabled="cargando" color="primary" @click="buscarDatos()">
              Buscar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-subtitle>Resultado</v-card-subtitle>
      <v-card-text>
        <tabletExpander v-if="datos.length>0"  :datos="datos" :dessertHeaders="dessertHeaders" :dessertDetalles="dessertDetalles" />
        <span v-else>No hya data</span>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline } from '@mdi/js'
import TablaSimpleReporte from '@/components/TablaSimpleReporte.vue'
import { onBeforeMount, ref } from '@vue/composition-api'
import SelectPuesto from '@/views/sistema/administracion/archivo/puesto/components/SelectPuestoMulti.vue' //'../../../archivo/puesto/components/SelectPuesto.vue';
import ProductoService from '@/api/servicios/ProductosService'
import CompraServices from '@/api/servicios/inventario/CompraServices'
import moment from 'moment'
import store from '@/store'
import SelectCategoria from '@/views/sistema/inventario/maestro/categoria/componentes/Select.vue'
import SelectSubCatoria from '@/views/sistema/inventario/maestro/sub-categoria/componentes/Select.vue'
import SelectAlmacen from '@/views/sistema/inventario/maestro/almacen/componentes/Select.vue'
import SelectProductoTipo from '@/views/sistema/inventario/maestro/producto-tipo/componentes/Select.vue'
import funciones from '@/funciones/funciones.js'
import tabletExpander from './componentes/tabletExpander.vue'
import SelectGrupoNegocio from '@/components/SelectGrupoNegocioValor.vue'
import DosFechas from '@/components/DosFechas.vue'
// demos

export default {
  components: {
    TablaSimpleReporte,
    SelectPuesto,
    SelectCategoria,
    SelectSubCatoria,
    SelectAlmacen,
    SelectProductoTipo,
    tabletExpander,
    SelectGrupoNegocio,
    DosFechas,
  },
  watch: {
    userCliente: function (old, news) {
      if (old != news) {
        this.filtros.id_sub_categoria = null
      }
    },
    'filtros.id_puesto': function (old, news) {
      if (old != news) {
        this.filtros.id_almacen = null
      }
    },
    id_puesto: function (old, news) {
      if (old != news) {
        this.filtros.id_puesto = old
      }
    },
  },
  setup(props) {
    onBeforeMount(() => {
      if (props.id_puesto != -1) {
        filtros.value.id_puesto = props.id_puesto
      }
    })

    const getFechas = datos => {
      console.log(datos)
      filtros.value.desde = datos.desde
      filtros.value.hasta = datos.hasta
    }
    const nombreReporte = ref(`Inventario actual`)
    const itemsExistencia = [
      {
        id: 0,
        text: 'TODOS',
      },
      {
        id: 1,
        text: 'SOLO POSITIVOS',
      },
      {
        id: 2,
        text: 'SOLO NEGATIVOS',
      },
      {
        id: 3,
        text: 'SOLO NEGATIVOS Y POSITIVOS',
      },
      {
        id: 4,
        text: 'SOLO EN CERO',
      },
    ]

    const cabecera = ref([
      { text: 'Id', value: 'producto.id', format: { small: true } },

      { text: 'Ref', value: 'producto.referencia', format: { small: true }, visible: false },
      { text: 'Producto', value: 'producto.nombre', format: { small: true } },
      { text: 'Tipo', value: 'producto_tipo.descripcion', format: { small: true } },
      { text: 'Categoria', value: 'categoria.nombre', format: { small: true } },
      { text: 'Sub Categoria', value: 'sub_categoria.nombre', format: { small: true } },
      { text: 'Area', value: 'puesto.nombre', format: { small: true } },
      { text: 'Almacen', value: 'almacen.descripcion', format: { small: true } },
      { text: 'Medida', value: 'unidad_medida.descripcion', format: { small: true } },
      {
        text: 'Existencia',
        value: 'existencia',
        format: {
          small: true,

          color: {
            condicional: dato => {
              if (dato > 0) {
                return 'success--text'
              } else if (dato < 0) {
                return 'error--text'
              } else {
                return 'secondary--text'
              }
            },
          },
        },
      },

      { text: 'Costo', value: 'costo', format: { small: true } },
      { text: 'Total Costo', value: 'costo_total', format: { small: true } },
    ])

    const datos = ref([])
    const filtros = ref({
      id_puesto: [],
      id_grupo_negocio: null,
      desde: moment().format('YYYY-MM-DD'),
      hasta: moment().format('YYYY-MM-DD'),
    })

    const cargando = ref(false)

    const buscarDatos = () => {
      try {
        datos.value = []
        cargando.value = true
        const _id_puesto =
          filtros.value.id_puesto && filtros.value.id_puesto.length > 0 ? filtros.value.id_puesto.join(',') : ''
        CompraServices.rptComprasCuentasContable({ ...filtros.value, id_puesto: _id_puesto })
          .then(response => {
            if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
              if (response.data.datos == null || response.data.datos.length == 0) {
                store.commit('setAlert', {
                  message: 'No hubo resultado a la busqueda',
                  type: 'info',
                })
              } else {
                datos.value = response.data.datos
                nombreReporte.value = `Reporte Inventario Actual`
              }
            } else {
              store.commit('setAlert', {
                message: response.data.mensaje,
                type: 'waring',
              })
            }
          })
          .catch(err => {})
          .finally(() => {
            cargando.value = false
          })
      } catch (error) {
        cargando.value = false
        store.commit('setAlert', {
          message: 'Error ' + error,
          type: 'warning',
        })
      }
    }

    return {
      cabecera,
      datos,
      buscarDatos,
      cargando,
      getFechas,
      nombreReporte,
      filtros,
      itemsExistencia,
      funciones,
      dessertHeaders: [
        { text: 'Codigo', value: 'codigo' },
        { text: 'Nombre', value: 'nombre' },
        { text: 'Total', value: 'total', align: 'right' },
      ],
      dessertDetalles: [
        { text: 'Numero Documento', value: 'numero_documento' },
        { text: 'Total', value: 'total', align: 'right' },
      ],
    }
  },
}
</script>
