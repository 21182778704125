<template>
  <div>
 
  <ComprasPorCuentaContableView :id_puesto="store.state.puestoSelect.id_puesto" />
</div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline } from '@mdi/js'
import ComprasPorCuentaContableView from './ComprasPorCuentaContableView.vue'
import store from '@/store'
// demos

export default {
  components: { ComprasPorCuentaContableView },
  setup() {
    return {store}
  },
}
</script>
./ComprasPorCuentaContableView.vue